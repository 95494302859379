<template>
    <div class="credit-sidebar-transactions">
        <div class="transactions-header">
            RECENT TRANSACTIONS
            <button v-on:click="$parent.depositSetSidebar(false)" class="button-close">
                <IconClose />
            </button>
        </div>
        <div class="transactions-content">
            <transition name="fade" mode="out-in">
                <div v-if="creditDepositData.loading === true" class="content-loading" key="loading">

                    <div class="loading-element"></div>
                    <div class="loading-element"></div>
                    <div class="loading-element"></div>
                    <div class="loading-element"></div>
                    <div class="loading-element"></div>

                </div>
                <div v-else-if="creditDepositData.transactions !== null" class="content-data" key="data">

                    <CreditTransactionElement v-for="transaction of creditDepositData.transactions" v-bind:key="transaction._id" v-bind:transaction="transaction" />

                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import IconClose from '@/components/icons/IconClose';
    import CreditTransactionElement from '@/components/credit/CreditTransactionElement';

    export default {
        name: 'CreditSidebarTransactions',
        components: {
            IconClose,
            CreditTransactionElement
        },
        computed: {
            ...mapGetters([
                'creditDepositData'
            ])
        }
    }
</script>

<style scoped>
    .credit-sidebar-transactions {
        width: 100%;
        height: 100%;
        padding: 18px;
    }

    .credit-sidebar-transactions .transactions-header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        color: #626c7e;
    }

    .credit-sidebar-transactions button.button-close {
        width: 15px;
        height: 15px;
        display: none;
        margin: 0;
        padding: 0;
    }

    .credit-sidebar-transactions button.button-close svg {
        fill: #626c7e;
        transition: all 0.3s ease;
    }

    .credit-sidebar-transactions button.button-close:hover svg {
        fill: #ffffff;
    }

    .credit-sidebar-transactions .transactions-content {
        width: 100%;
        margin-top: 16px;
    }

    .credit-sidebar-transactions .content-loading {
        width: 100%;
    }

    .credit-sidebar-transactions .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .credit-sidebar-transactions .content-loading.fade-leave-to {
        opacity: 0;
    }

    .credit-sidebar-transactions .loading-element {
        width: 100%;
        height: 41px;
        position: relative;
        margin-top: 11px;
        border-radius: 5px;
        background: #212732;
        overflow: hidden;
    }

    .credit-sidebar-transactions .loading-element:first-of-type {
        margin-top: 0;
    }

    .credit-sidebar-transactions .loading-element::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(to right, #ffffff00 0%, rgba(255, 255, 255, .1) 50%, #ffffff00 100%);
    }

    @keyframes loading_animation {
        0% { transform: translateX(-100%); }
        50% { transform: translateX(100%); }
        100% { transform: translateX(100%); }
    }

    .credit-sidebar-transactions .content-data {
        width: 100%;
    }

    .credit-sidebar-transactions .content-data.fade-enter-active {
        transition: opacity 0.5s;
    }

    .credit-sidebar-transactions .content-data.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1300px) {

        .credit-sidebar-transactions .transactions-header {
            justify-content: space-between;
        }

        .credit-sidebar-transactions button.button-close {
            display: flex;
            align-items: center;
        }

    }
</style>