<template>
    <div class="profile-settings">

        <div class="settings-element element-text">
            <div class="element-title">STEAM TRADE URL</div>
            <div class="element-info">
                Your steam trade url is required to trade with you. You can find it <a href='https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url' target='_blank'>here</a>.
            </div>
            <div class="element-input">
                <input v-model="profileTradeLink" type="text" placeholder="Enter steam trade link..." />
                <button v-on:click="profileTradeLinkButton()" class="button-set" v-bind:disabled="socketSendLoading !== null">
                    <transition name="fade" mode="out-in">
                        <div class="button-loading" v-if="socketSendLoading === 'ProfileTradeLink'" key="loading">
                            <LoadingAnimation />
                        </div>
                        <div class="button-content" v-else key="content">SET TRADE URL</div>
                    </transition>
                </button>
            </div>
        </div>

        <div class="settings-element element-toggle">
            <div class="element-title">ANONYMOUS MODE</div>
            <div class="element-input">
                <button v-on:click="profileAnonymousToggle()" class="button-toggle" v-bind:class="{ 
                    'button-active': authUser.user.anonymous === true 
                }" v-bind:disabled="socketSendLoading !== null"></button>
            </div>
        </div>

        <div class="settings-element element-range">
            <div class="element-title">SOUND VOLUME</div>
            <div class="element-input">
                <input type="range" min="0" max="100" step="1" v-model="profileVolume" v-on:input="profileSetVolume" v-bind:style="{ 'background-image': '-webkit-gradient(linear, left top, right top, color-stop('+profileVolumePercentage+', #fd3b31), color-stop('+profileVolumePercentage+', #191e27))' }">
            </div>
        </div>
        
        <div class="settings-element element-button">
            <div class="element-title">LINK DISCORD</div>
            <div class="element-input">
                <button v-on:click="profileDiscordButton()" class="button-discord" v-bind:disabled="authUser.user.discord !== undefined">
                    <IconDiscord />
                    {{ authUser.user.discord !== undefined ? 'DISCORD LINKED' : 'LINK DISCORD' }}
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconDiscord from '@/components/icons/IconDiscord';
    import LoadingAnimation from '@/components/LoadingAnimation';

    export default {
        name: 'ProfileSettings',
        components: {
            IconDiscord,
            LoadingAnimation
        },
        data() {
            return {
                profileBackendUrl: process.env.VUE_APP_BACKEND_URL,
                profileTradeLink: '',
                profileVolume: 50
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'soundSetVolume',
                'userSendSettingSocket',
            ]),
            profileTradeLinkButton() {
                if(this.profileTradeLink === null || this.profileTradeLink.trim() === '' || this.profileTradeLink.match(/steamcommunity\.com\/tradeoffer\/new\/\?partner=[0-9]*&token=[a-zA-Z0-9_-]*/i) === null) {
                    this.notificationShow({ type: 'error', message: 'Your entered steam trade link is invalid.' });
                    return;
                }

                this.userSendSettingSocket({ setting: 'tradeLink', value: this.profileTradeLink });
            },
            profileDiscordButton() {
                window.location.href = this.profileBackendUrl + '/api/auth/discord';
            },
            profileAnonymousToggle() {
                this.userSendSettingSocket({ setting: 'anonymous', value: !this.authUser.user.anonymous });
            },
            profileSetVolume() {
                this.soundSetVolume(this.profileVolume / 100);
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading', 
                'authUser',
                'soundVolume'
            ]),
            profileVolumePercentage() {
                return this.profileVolume / 100;
            }
        },
        created() {
            this.profileVolume = this.soundVolume * 100;
            this.profileTradeLink = this.authUser.user.tradeLink !== undefined ? this.authUser.user.tradeLink : '';
        }
    }
</script>

<style scoped>
    .profile-settings {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .profile-settings .settings-element {
        width: 100%;
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        background: #212732;
    }

    .profile-settings .settings-element:first-of-type {
        margin-top: 0;
    }

    .profile-settings .settings-element.element-toggle,
    .profile-settings .settings-element.element-range,
    .profile-settings .settings-element.element-button {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .profile-settings .element-title {
        padding: 0 4px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
    }

    .profile-settings .element-info {
        padding: 0 4px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        color: #767c8b;
    }

    .profile-settings .element-info a {
        color: #fd3b31;
    }

    .profile-settings .element-input {
        display: flex;
        align-items: center;
    }

    .profile-settings .settings-element.element-text .element-input {
        width: 100%;
        position: relative;
        margin-top: 8px;
    }

    .profile-settings .element-input input[type="text"] {
        width: 100%;
        height: 54px;
        padding: 0 148px 0 17px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .profile-settings .element-input input[type="text"]::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .profile-settings button.button-set {
        width: 136px;
        height: 41px;
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translate(0, -50%);
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    .profile-settings button.button-set .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .profile-settings button.button-set .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .profile-settings button.button-set .button-loading.fade-leave-to {
        opacity: 0;
    }

    .profile-settings button.button-set .button-content.fade-enter-active {
        transition: opacity 0.5s;
    }

    .profile-settings button.button-set .button-content.fade-enter-from {
        opacity: 0;
    }

    .profile-settings button.button-toggle {
        width: 56px;
        height: 30px;
        position: relative;
    }

    .profile-settings button.button-toggle:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 34px;
        background: rgba(253, 59, 49, 0.4);
    }

    .profile-settings button.button-toggle.button-active:before {
        background: rgba(0, 199, 77, 0.4);
    }

    .profile-settings button.button-toggle:after {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        left: 4px;
        top: 4px;
        border-radius: 50%;
        background: #fd3b31;
        transition: transform 0.3s ease;
    }

    .profile-settings button.button-toggle.button-active:after {
        transform: translateX(26px);
        background: #00c74d;
    }

    .profile-settings .element-input input[type="range"] {
        width: 250px;
        height: 6px;
        border-radius: 3px;
        -webkit-appearance: none;
        -moz-apperance: none;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .profile-settings .element-input input[type="range"]::-webkit-slider-thumb {
        width: 16px;
        height: 16px;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 100%;
        background: #fd3b31;
        cursor: pointer;
    }

    .profile-settings .element-input input[type="range"]::-moz-range-thumb {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background: #fd3b31;
        cursor: pointer;
    }

    .profile-settings button.button-discord {
        width: 160px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #5865f2;
        border-bottom: 2px solid #454fc4;
    }

    .profile-settings button.button-discord svg {
        width: 24px;
        margin-right: 8px;
        fill: #ffffff;
    }
</style>
