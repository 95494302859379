<template>
    <div class="profile-bets-element">
        <div class="element-section section-type">
            <div class="section-title">TYPE</div>
            <div class="section-content">
                {{ this.transaction.method.toUpperCase() }} BET
            </div>
        </div>
        <div class="element-section section-amount">
            <div class="section-title">AMOUNT</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="amount-value" v-bind:class="{ 'value-negative': profileGetAmount < 0 }">
                    <span>{{generalFormatAmount(profileGetAmount).split('.')[0]}}</span>.{{generalFormatAmount(profileGetAmount).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-date">
            <div class="section-title">DATE</div>
            <div class="section-content">
                {{ new Date(transaction.createdAt).toLocaleString('en-US', { hour12: true }) }}
            </div>
        </div>
        <div class="element-section section-bet">
            <div class="section-title">BET ID</div>
            <div class="section-content">
                {{ this.transaction._id }}
            </div>
        </div>
        <div class="element-section section-actions">
            <div class="section-title">ACTIONS</div>
            <div class="section-content">
                <button v-on:click="profileVerifyButton()" class="button-verify">VERIFY</button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapActions } from 'vuex';

    export default {
        name: 'ProfileBetsElement',
        mixins: [
            mixins
        ],
        props: [
            'transaction'
        ],
        methods: {
            ...mapActions([
                'modalsSetShow', 
                'modalsSetData'
            ]),
            profileVerifyButton() {
                this.modalsSetData({ ...this.transaction });
                this.modalsSetShow('FairInfo');
            }
        },
        computed: {
            profileGetAmount() {
                let amount = this.transaction.amount;

                if(this.transaction.method === 'blackjack') {
                    amount = this.transaction.actions.includes('split') === true || this.transaction.actions.includes('double') === true ? Math.floor(amount * 2) : amount;
                    amount = this.transaction.actions.includes('insurance') ? amount + Math.floor(this.transaction.amount * 0.5) : amount;
                }

                return Math.floor(this.transaction.payout - amount);
            }
        }
    }
</script>

<style scoped>
    .profile-bets-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .profile-bets-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .profile-bets-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .profile-bets-element .element-section.section-type,
    .profile-bets-element .element-section.section-date,
    .profile-bets-element .element-section.section-bet {
        width: 25%;
    }

    .profile-bets-element .element-section.section-amount {
        width: 15%;
    }

    .profile-bets-element .element-section.section-actions {
        width: 10%;
    }

    .profile-bets-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .profile-bets-element .section-content {
        display: flex;
        align-items: center;
    }

    .profile-bets-element .element-section.section-type .section-content,
    .profile-bets-element .element-section.section-date .section-content,
    .profile-bets-element .element-section.section-bet .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .profile-bets-element .element-section.section-type .section-content,
    .profile-bets-element .element-section.section-date .section-content {
        text-transform: uppercase;
    }

    .profile-bets-element .element-section.section-actions .section-content {
        justify-content: flex-end;
    }

    .profile-bets-element .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .profile-bets-element .amount-value {
        font-size: 12px;
        font-weight: 700;
        color: #00c74d;
    }

    .profile-bets-element .amount-value.value-negative {
        color: #fd3b31;
    }

    .profile-bets-element .amount-value span {
        font-size: 15px;
        font-weight: 700;
    }

    .profile-bets-element .section-content button.button-verify {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
    }

    .profile-bets-element .section-content button.button-verify:hover {
        color: #ffffff;
    }

    @media only screen and (max-width: 1300px) {

        .profile-bets-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .profile-bets-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .profile-bets-element .element-section.section-type {
            margin-top: 0;
        }

        .profile-bets-element .element-section.section-actions {
            align-items: flex-start;
        }

        .profile-bets-element .section-title {
            display: block;
        }

        .profile-bets-element .section-content {
            margin-top: 5px;
        }

    }
</style>
