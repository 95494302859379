<template>
    <div class="profile-transactions">
        <div class="transactions-content">
            <div class="content-header">
                <div class="header-element element-type">TYPE</div>
                <div class="header-element element-amount">AMOUNT</div>
                <div class="header-element element-date">DATE</div>
                <div class="header-element element-transaction">TRANSACTION</div>
            </div>
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="userTransactionsData.transactions === null || userTransactionsData.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="userTransactionsData.transactions.length > 0" class="list-data" key="data">

                        <ProfileTransactionsElement v-for="transaction in userTransactionsData.transactions" v-bind:key="transaction._id" v-bind:transaction="transaction" />

                    </div>
                    <div v-else class="list-empty" key="empty">NO TRANSACTIONS FOUND.</div>
                </transition>
            </div>
        </div>

        <Pagination v-on:setPage="profileSetPage" v-bind:page="userTransactionsData.page" v-bind:count="userTransactionsData.count" countPage="14" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import ProfileTransactionsElement from '@/components/profile/ProfileTransactionsElement';

    export default {
        name: 'ProfileTransactions',
        components: {
            LoadingAnimation,
            Pagination,
            ProfileTransactionsElement
        },
        methods: {
            ...mapActions([
                'userGetTransactionsSocket', 
                'userSetTransactionsDataPage'
            ]),
            profileSetPage(page) {
                this.userSetTransactionsDataPage(page);
                this.userGetTransactionsSocket({ page: this.userTransactionsData.page });
            }
        },
        computed: {
            ...mapGetters([
                'userTransactionsData'
            ])
        },
        created() {
            if(this.userTransactionsData.loading === false) {
                this.userGetTransactionsSocket({ page: this.userTransactionsData.page });
            }
        }
    }
</script>

<style scoped>
    .profile-transactions {
        width: 100%;
    }

    .profile-transactions .transactions-content {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1E29 0%, #1c202c 100%);
    }

    .profile-transactions .content-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .profile-transactions .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .profile-transactions .header-element.element-type,
    .profile-transactions .header-element.element-date {
        width: 25%;
    }

    .profile-transactions .header-element.element-amount {
        width: 15%;
    }

    .profile-transactions .header-element.element-transaction {
        width: 35%;
    }

    .profile-transactions .content-list {
        width: 100%;
        margin-top: 8px;
    }

    .profile-transactions .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .profile-transactions .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .profile-transactions .list-loading.fade-leave-to {
        opacity: 0;
    }

    .profile-transactions .list-data {
        width: 100%;
    }

    .profile-transactions .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .profile-transactions .list-data.fade-enter-active,
    .profile-transactions .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .profile-transactions .list-data.fade-enter-from,
    .profile-transactions .list-empty.fade-enter-from {
        opacity: 0;
    }
    
    @media only screen and (max-width: 1300px) {

        .profile-transactions  .transactions-content {
            padding: 10px;
        }

        .profile-transactions .content-header {
            display: none;
        }

        .profile-transactions .content-list {
            width: 100%;
            margin-top: 0;
        }

    }
</style>
