<template>
    <div class="withdraw">
        <div class="withdraw-nav">
            <router-link to="/withdraw">
                <IconRust />
                RUST SKINS
            </router-link>
        </div>

        <div class="withdraw-content">
            <transition name="slide-fade" mode="out-in">
                <router-view/>
            </transition>
        </div>

        <div class="withdraw-sidebar"  v-bind:class="{ 'sidebar-open': withdrawSidebar === true }">
            <div class="sidebar-toggle">
                <button v-on:click="withdrawSetSidebar(true)">
                    <IconChevronLeft />
                </button>
            </div>

            <SteamSidebarSelected />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import IconRust from '@/components/icons/IconRust';
    import IconChevronLeft from '@/components/icons/IconChevronLeft';
    import SteamSidebarSelected from '@/components/steam/SteamSidebarSelected';

    export default {
        name: 'Withdraw',
        metaInfo: {
            title: 'Withdraw - RustyRocket.GG'
        },
        components: {
            IconRust,
            IconChevronLeft,
            SteamSidebarSelected
        },
        data() {
            return {
                withdrawSidebar: false
            }
        },
        methods: {
            withdrawSetSidebar(status) {
                this.withdrawSidebar = status;
            }
        },
        computed: {
            ...mapGetters([
                'generalSettings'
            ])
        },
        created() {
            window.addEventListener('resize', (event) => {
                if(this.withdrawSidebar === false) { return; }
                if(window.innerWidth <= 1200) { return; }
                this.withdrawSetSidebar(false);
            });
        },
        beforeRouteLeave(to, from, next) {
            window.removeEventListener('resize', (event) => {});
            next();
        }
    }
</script>

<style scoped>
    .withdraw {
        width: 100%;
        min-height: calc(100vh - 112px);
        padding: 40px 415px 40px 40px;
    }

    .withdraw .withdraw-nav {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .withdraw .withdraw-nav a {
        height: 41px;
        display: flex;
        align-items: center;
        margin-right: 12px;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
    }

    .withdraw .withdraw-nav a:last-of-type {
        margin-right: 0;
    }

    .withdraw .withdraw-nav a:hover {
        color: #ffffff;
    }

    .withdraw .withdraw-nav a.router-link-exact-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .withdraw .withdraw-nav a svg {
        margin-right: 10px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .withdraw .withdraw-nav a.router-link-exact-active svg {
        fill: #fd3b31;
    }

    .withdraw .withdraw-content {
        width: 100%;
    }

    .withdraw .withdraw-content .slide-fade-enter-active {
        transition: all .3s ease-out;
    }

    .withdraw .withdraw-content .slide-fade-enter {
        opacity: 0;
    }

    .withdraw .withdraw-sidebar {
        width: 360px;
        height: calc(100vh - 142px);
        position: fixed;
        top: 127px;
        right: 15px;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
        transition: all 0.3s ease;
        z-index: 5;
    }

    .withdraw .sidebar-toggle {
        position: absolute;
        display: none;
        left: -56px;
        bottom: 43px;
    }

    .withdraw .sidebar-toggle button {
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px 0 0 5px;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .withdraw .sidebar-toggle button svg {
        height: 20px;
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .withdraw .sidebar-toggle button:hover svg {
        fill: #ffffff;
    }

    @media only screen and (max-width: 1850px) {

        .withdraw {
            padding: 40px 390px 40px 15px;
        }

    }

    @media only screen and (max-width: 1300px) {

        .withdraw {
            padding: 40px 15px;
        }

        .withdraw .withdraw-sidebar {
            width: 400px;
            height: calc(100% - 67px);
            position: fixed;
            top: 67px;
            right: -400px;
            border-radius: 0;
        }

        .withdraw .withdraw-sidebar.sidebar-open {
            right: 0;
        }

        .withdraw .sidebar-toggle {
            display: block;
        }

        .withdraw .withdraw-sidebar.sidebar-open .sidebar-toggle {
            display: none;
        }

    }

    @media only screen and (max-width: 475px) {

        .withdraw .withdraw-nav {
            flex-direction: column;
            align-items: flex-start;
        }

        .withdraw .withdraw-nav a {
            width: 100%;
            justify-content: center;
            margin-top: 8px;
            margin-right: 0;
        }

        .withdraw .withdraw-nav a:first-of-type {
            margin-top: 0;
        }

    }

    @media only screen and (max-width: 400px) {

        .withdraw .withdraw-sidebar.sidebar-open {
            width: 100%;
        }

    }
</style>
