<template>
    <div class="profile-bets">
        <div class="bets-content">
            <div class="content-header">
                <div class="header-element element-type">TYPE</div>
                <div class="header-element element-amount">AMOUNT</div>
                <div class="header-element element-date">DATE</div>
                <div class="header-element element-bet">BET ID</div>
                <div class="header-element element-actions">ACTIONS</div>
            </div>
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="userBetsData.bets === null || userBetsData.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="userBetsData.bets.length > 0" class="list-data" key="data">

                        <ProfileBetsElement v-for="transaction in userBetsData.bets" v-bind:key="transaction._id" v-bind:transaction="transaction" />

                    </div>
                    <div v-else class="list-empty" key="empty">NO BETS FOUND.</div>
                </transition>
            </div>
        </div>

        <Pagination v-on:setPage="profileSetPage" v-bind:page="userBetsData.page" v-bind:count="userBetsData.count" countPage="14" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import ProfileBetsElement from '@/components/profile/ProfileBetsElement';

    export default {
        name: 'ProfileBets',
        components: {
            LoadingAnimation,
            Pagination,
            ProfileBetsElement
        },
        methods: {
            ...mapActions([
                'userGetBetsSocket', 
                'userSetBetsDataPage'
            ]),
            profileSetPage(page) {
                this.userSetBetsDataPage(page);
                this.userGetBetsSocket({ page: this.userBetsData.page });
            }
        },
        computed: {
            ...mapGetters([
                'userBetsData'
            ])
        },
        created() {
            if(this.userBetsData.loading === false) {
                this.userGetBetsSocket({ page: this.userBetsData.page });
            }
        }
    }
</script>

<style scoped>
    .profile-bets {
        width: 100%;
    }

    .profile-bets  .bets-content {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1A1E29 0%, #1C202C 100%);
    }

    .profile-bets .content-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .profile-bets .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .profile-bets .header-element.element-type,
    .profile-bets .header-element.element-date,
    .profile-bets .header-element.element-bet {
        width: 25%;
    }

    .profile-bets .header-element.element-amount {
        width: 15%;
    }

    .profile-bets .header-element.element-actions {
        width: 10%;
        display: flex;
        justify-content: flex-end;
    }

    .profile-bets .content-list {
        width: 100%;
        margin-top: 8px;
    }

    .profile-bets .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .profile-bets .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .profile-bets .list-loading.fade-leave-to {
        opacity: 0;
    }

    .profile-bets .list-data {
        width: 100%;
    }

    .profile-bets .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .profile-bets .list-data.fade-enter-active,
    .profile-bets .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .profile-bets .list-data.fade-enter-from,
    .profile-bets .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1300px) {

        .profile-bets  .bets-content {
            padding: 10px;
        }

        .profile-bets .content-header {
            display: none;
        }

        .profile-bets .content-list {
            width: 100%;
            margin-top: 0;
        }

    }
</style>
