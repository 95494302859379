<template>
    <div class="deposit">
        <div class="deposit-nav">
            <router-link to="/deposit">
                <IconRust />
                RUST SKINS
            </router-link>
            <router-link v-if="generalSettings.crypto.deposit.enabled === true" to="/deposit/crypto">
                <IconCrypto />
                CRYPTO
            </router-link>
            <router-link v-if="generalSettings.gift.deposit.enabled === true" to="/deposit/gift">
                <IconGift />
                GIFTCARD
            </router-link>
            <router-link v-if="generalSettings.credit.deposit.enabled === true" to="/deposit/credit" class="link-creditcard">
                <IconCredit />
                CREDITCARD
                <div class="link-tag">NEW</div>
            </router-link>
        </div>
        <div class="deposit-content">
            <transition name="slide-fade" mode="out-in">
                <router-view/>
            </transition>
        </div>

        <div class="deposit-sidebar" v-bind:class="{ 'sidebar-open': depositSidebar === true }">
            <div class="sidebar-toggle">
                <button v-on:click="depositSetSidebar(true)">
                    <IconChevronLeft />
                </button>
            </div>

            <SteamSidebarSelected v-if="$route.name === 'DepositRust'" />
            <CryptoSidebarTransactions v-else-if="$route.name === 'DepositCrypto'" />
            <GiftSidebarTransactions v-else-if="$route.name === 'DepositGift'" />
            <CreditSidebarTransactions v-else />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import IconRust from '@/components/icons/IconRust';
    import IconCrypto from '@/components/icons/IconCrypto';
    import IconGift from '@/components/icons/IconGift';
    import IconCredit from '@/components/icons/IconCredit';
    import IconChevronLeft from '@/components/icons/IconChevronLeft';
    import SteamSidebarSelected from '@/components/steam/SteamSidebarSelected';
    import CryptoSidebarTransactions from '@/components/crypto/CryptoSidebarTransactions';
    import GiftSidebarTransactions from '@/components/gift/GiftSidebarTransactions';
    import CreditSidebarTransactions from '@/components/credit/CreditSidebarTransactions';

    export default {
        name: 'Deposit',
        metaInfo: {
            title: 'Deposit - RustyRocket.GG'
        },
        components: {
            IconRust,
            IconCrypto,
            IconGift,
            IconCredit,
            IconChevronLeft,
            SteamSidebarSelected,
            CryptoSidebarTransactions,
            GiftSidebarTransactions,
            CreditSidebarTransactions
        },
        data() {
            return {
                depositSidebar: false
            }
        },
        methods: {
            depositSetSidebar(status) {
                this.depositSidebar = status;
            }
        },
        computed: {
            ...mapGetters([
                'generalSettings'
            ])
        },
        created() {
            window.addEventListener('resize', (event) => {
                if(this.depositSidebar === false) { return; }
                if(window.innerWidth <= 1200) { return; }
                this.depositSetSidebar(false);
            });
        },
        beforeRouteLeave(to, from, next) {
            window.removeEventListener('resize', (event) => {});
            next();
        }
    }
</script>

<style scoped>
    .deposit {
        width: 100%;
        min-height: calc(100vh - 112px);
        padding: 40px 415px 40px 40px;
    }

    .deposit .deposit-nav {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .deposit .deposit-nav a {
        height: 41px;
        display: flex;
        align-items: center;
        margin-right: 12px;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
    }

    .deposit .deposit-nav a:last-of-type {
        margin-right: 0;
    }

    .deposit .deposit-nav a:hover {
        color: #ffffff;
    }

    .deposit .deposit-nav a.link-creditcard {
        color: #00c74d;
        box-shadow: 0px 0px 0px 1px #00732c;
    }

    .deposit .deposit-nav a.router-link-exact-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .deposit .deposit-nav a svg {
        margin-right: 10px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .deposit .deposit-nav a.link-creditcard svg {
        fill: #00c74d;
    }

    .deposit .deposit-nav a.router-link-exact-active svg {
        fill: #fd3b31;
    }

    .deposit .deposit-nav a .link-tag {
        height: 20px;
        display: flex;
        align-items: center;
        margin-left: 9px;
        padding: 0 6px;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        transition: background 0.3s ease;
    }

    .deposit .deposit-nav a.router-link-exact-active .link-tag {
        background: #fd3b31;
    }

    .deposit .deposit-content {
        width: 100%;
    }

    .deposit .deposit-content .slide-fade-enter-active {
        transition: all .3s ease-out;
    }

    .deposit .deposit-content .slide-fade-enter {
        opacity: 0;
    }

    .deposit .deposit-sidebar {
        width: 360px;
        height: calc(100vh - 142px);
        position: fixed;
        top: 127px;
        right: 15px;
        border-radius: 18px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
        transition: all 0.3s ease;
        z-index: 5;
    }

    .deposit .sidebar-toggle {
        position: absolute;
        display: none;
        left: -56px;
        bottom: 43px;
    }

    .deposit .sidebar-toggle button {
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px 0 0 5px;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .deposit .sidebar-toggle button svg {
        height: 20px;
        fill: #767c8b;
        transition: fill 0.3s ease;
    }

    .deposit .sidebar-toggle button:hover svg {
        fill: #ffffff;
    }

    @media only screen and (max-width: 1850px) {

        .deposit {
            padding: 40px 390px 40px 15px;
        }

    }

    @media only screen and (max-width: 1300px) {

        .deposit {
            padding: 40px 15px;
        }

        .deposit .deposit-sidebar {
            width: 400px;
            height: calc(100% - 67px);
            position: fixed;
            top: 67px;
            right: -400px;
            border-radius: 0;
        }

        .deposit .deposit-sidebar.sidebar-open {
            right: 0;
        }

        .deposit .sidebar-toggle {
            display: block;
        }

        .deposit .deposit-sidebar.sidebar-open .sidebar-toggle {
            display: none;
        }

    }

    @media only screen and (max-width: 650px) {

        .deposit .deposit-nav {
            flex-direction: column;
            align-items: flex-start;
        }

        .deposit .deposit-nav a {
            width: 100%;
            justify-content: center;
            margin-top: 8px;
            margin-right: 0;
        }

        .deposit .deposit-nav a:first-of-type {
            margin-top: 0;
        }

    }

    @media only screen and (max-width: 400px) {

        .deposit .deposit-sidebar.sidebar-open {
            width: 100%;
        }

    }
</style>
