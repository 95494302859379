<template>
    <div class="deposit-gift">
        <div class="gift-title">
            <div class="title-value"><span>GIFT</span>CARD</div>
            <div class="title-bonus">+35% BONUS</div>
        </div>
        <div class="gift-redeem">
            <input v-model="depositCode" type="text" placeholder="XXXX - XXXX - XXXX - XXXX" />
            <button v-on:click="depositRedeemButton()" v-bind:disabled="socketSendLoading !== null">
                <transition name="fade" mode="out-in">
                    <div v-if="socketSendLoading === 'GiftClaim'" class="button-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else class="button-content" key="content">
                        REDEEM GIFTCARD
                    </div>
                </transition>
            </button>
        </div>
        <div class="gift-select">
            <div class="select-actions">
                <div class="actions-method">
                    <div class="method-element">
                        <img src="@/assets/img/icons/kinguin.svg" />
                    </div>
                </div>
                <a v-bind:href="depositGetLink" target="_blank">BUY SELECTED GIFTCARD</a>
            </div>
            <div class="select-options">

                <button v-on:click="depositSetGift('5')" v-bind:class="['options-element', { 
                    'element-active': depositGift === '5' 
                }]">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="element-amount">
                        <span>5</span>.00
                    </div>
                </button>
                <button v-on:click="depositSetGift('10')" v-bind:class="['options-element', { 
                    'element-active': depositGift === '10' 
                }]">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="element-amount">
                        <span>10</span>.00
                    </div>
                </button>
                <button v-on:click="depositSetGift('25')" v-bind:class="['options-element', { 
                    'element-active': depositGift === '25' 
                }]">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="element-amount">
                        <span>25</span>.00
                    </div>
                </button>
                <button v-on:click="depositSetGift('50')" v-bind:class="['options-element', { 
                    'element-active': depositGift === '50' 
                }]">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="element-amount">
                        <span>50</span>.00
                    </div>
                </button>
                <button v-on:click="depositSetGift('100')" v-bind:class="['options-element', { 
                    'element-active': depositGift === '100' 
                }]">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="element-amount">
                        <span>100</span>.00
                    </div>
                </button>
                <button v-on:click="depositSetGift('250')" v-bind:class="['options-element', { 
                    'element-active': depositGift === '250' 
                }]">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="element-amount">
                        <span>250</span>.00
                    </div>
                </button>
                <button v-on:click="depositSetGift('500')" v-bind:class="['options-element', { 
                    'element-active': depositGift === '500' 
                }]">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="element-amount">
                        <span>500</span>.00
                    </div>
                </button>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';

    export default {
        name: 'DepositGift',
        components: {
            LoadingAnimation
        },
        data() {
            return {
                depositCode: null,
                depositGift: '5'
            }
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'modalsSetShow',
                'modalsSetData', 
                'giftGetDepositDataSocket'
            ]),
            depositRedeemButton() {
                if(this.depositCode === null || this.depositCode.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your provided gift code is invalid.' });
                    return;
                }

                this.modalsSetData({ type: 'giftClaim', data: { code:  this.depositCode.replaceAll('-', '') } });
                this.modalsSetShow('Captcha');

                this.depositCode = null;
            },
            depositSetGift(gift) {
                this.depositGift = gift;
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'generalSettings', 
                'giftDepositData'
            ]),
            depositGetLink() {
                let link = 'https://www.kinguin.net/category/125091/rusty-rocket-5-coin-gift-card?referrer=rustyrocket.gg&ec=1';

                if(this.depositGift === '10') {
                    return 'https://www.kinguin.net/category/128769/rusty-rocket-10-coin-gift-card?referrer=rustyrocket.gg&ec=1';
                } else if(this.depositGift === '25') {
                    return 'https://www.kinguin.net/category/128770/rusty-rocket-25-coin-gift-card?referrer=rustyrocket.gg&ec=1';
                } else if(this.depositGift === '50') {
                    return 'https://www.kinguin.net/category/128771/rusty-rocket-50-coin-gift-card?referrer=rustyrocket.gg&ec=1';
                } else if(this.depositGift === '100') {
                    return 'https://www.kinguin.net/category/128772/rusty-rocket-100-coin-gift-card?referrer=rustyrocket.gg&ec=1';
                } else if(this.depositGift === '250') {
                    return 'https://www.kinguin.net/category/128773/rusty-rocket-250-coin-gift-card?referrer=rustyrocket.gg&ec=1';
                } else if(this.depositGift === '500') {
                    return 'https://www.kinguin.net/category/128775/rusty-rocket-500-coin-gift-card?referrer=rustyrocket.gg&ec=1';
                }

                return link;
            }
        },
        created() {
            if(this.generalSettings.gift.deposit.enabled === false) {
                this.$router.push({ name: 'DepositRust' });
            }

            if(this.giftDepositData.transactions === null && this.giftDepositData.loading === false) {
                this.giftGetDepositDataSocket();
            }
        }
    }
</script>

<style scoped>
    .deposit-gift {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 35px;
    }
    
    .deposit-gift .gift-title {
        display: flex;
        align-items: center;
    }

    .deposit-gift .title-value {
        font-size: 20px;
        font-weight: 800;
        color: #323743;
    }

    .deposit-gift .title-value span {
        font-size: 26px;
    }

    .deposit-gift .title-bonus {
        height: 26px;
        display: flex;
        align-items: center;
        margin-left: 12px;
        padding: 0 8px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
    }

    .deposit-gift .gift-redeem {
        height: 70px;
        display: flex;
        align-items: center;
        margin-top: 18px;
        padding: 0 15px 0 8px;
        border-radius: 8px;
        background: #11141f;
    }

    .deposit-gift .gift-redeem input {
        width: 470px;
        height: 54px;
        padding: 0 18px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .deposit-gift .gift-redeem input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .deposit-gift .gift-redeem button {
        width: 161px;
        height: 41px;
        margin-left: 42px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    .deposit-gift .gift-redeem button:hover {
        background: #fe524a;
    }

    .deposit-gift .gift-redeem button.button-loading {
        background: #fd3b31;
        cursor: not-allowed;
    }

    .deposit-gift .gift-redeem button .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .deposit-gift .gift-redeem button .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .deposit-gift .gift-redeem button .button-loading.fade-leave-to {
        opacity: 0;
    }

    .deposit-gift .gift-redeem button .button-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .deposit-gift .gift-redeem button .button-content.fade-enter-active {
        transition: opacity 0.5s;
    }

    .deposit-gift .gift-redeem button .button-content.fade-enter-from {
        opacity: 0;
    }

    .deposit-gift .gift-select {
        width: 100%;
        margin-top: 26px;
    }

    .deposit-gift .select-actions {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 18px;
        border-radius: 8px;
        background: #11141f;
    }

    .deposit-gift .select-actions a {
        width: 210px;
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .deposit-gift .select-actions a:hover {
        background: #00de56;
    }

    .deposit-gift .actions-method {
        display: flex;
        align-items: center;
    }

    .deposit-gift .method-element {
        display: flex;
        align-items: center;
        margin-right: 28px;
        mix-blend-mode: luminosity;
        opacity: 0.3;
    }

    .deposit-gift .method-element:last-of-type {
        margin-right: 0;
    }

    .deposit-gift .method-element img {
        height: 26px;
    }

    .deposit-gift .select-options {
        width: 100%;
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
    }

    .deposit-gift button.options-element {
        width: calc(20% - 14.4px);
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 18px;
        margin-bottom: 18px;
        border-radius: 14px;
        background: linear-gradient(113.58deg, #1f2330 0%, #303546 47.4%, #1f2330 100%);
        border-left: 36px solid #1e2230;
        opacity: 0.24;
    }

    .deposit-gift button.options-element.element-active {
        opacity: 1;
    }

    .deposit-gift button.options-element:nth-child(5n) {
        margin-right: 0;
    }

    .deposit-gift button.options-element img {
        width: 35px;
        height: 35px;
        margin-right: 12px;
    }

    .deposit-gift button.options-element .element-amount {
        font-size: 20px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .deposit-gift button.options-element .element-amount span {
        font-size: 26px;
        font-weight: 700;
        color: #ffffff;
    }

    @media only screen and (max-width: 1450px) {

        .deposit-gift button.options-element {
            width: calc(25% - 13.5px);
        }

        .deposit-gift button.options-element:nth-child(5n) {
            margin-right: 18px;
        }

        .deposit-gift button.options-element:nth-child(4n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 1250px) {

        .deposit-gift button.options-element {
            width: calc(33.33% - 12px);
        }

        .deposit-gift button.options-element:nth-child(4n) {
            margin-right: 18px;
        }

        .deposit-gift button.options-element:nth-child(3n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 750px) {

        .deposit-gift .cards-redeem {
            width: 100%;
            height: 119px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 8px
        }

        .deposit-gift .cards-redeem input {
            width: 100%;
        }

        .deposit-gift .cards-redeem button {
            width: 100%;
            margin-top: 8px;
            margin-left: 0;
        }

    }

    @media only screen and (max-width: 650px) {

        .deposit-gift button.options-element {
            width: calc(50% - 9px);
        }

        .deposit-gift button.options-element:nth-child(3n) {
            margin-right: 18px;
        }

        .deposit-gift button.options-element:nth-child(2n) {
            margin-right: 0;
        }

    }
</style>
