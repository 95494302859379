<template>
    <div class="deposit-crypto">
        <div class="crypto-title">
            <div class="title-value"><span>CRYPTO</span>CURRENCY</div>
            <div class="title-bonus">+35% BONUS</div>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="cryptoDepositData.loading === true" class="crypto-loading" key="loading">

                <div class="loading-element">
                    <div class="element-image"></div>
                    <div class="element-name"></div>
                </div>
                <div class="loading-element">
                    <div class="element-image"></div>
                    <div class="element-name"></div>
                </div>
                <div class="loading-element">
                    <div class="element-image"></div>
                    <div class="element-name"></div>
                </div>
                <div class="loading-element">
                    <div class="element-image"></div>
                    <div class="element-name"></div>
                </div>
                <div class="loading-element">
                    <div class="element-image"></div>
                    <div class="element-name"></div>
                </div>

            </div>
            <div v-else class="crypto-content" key="data">

                <CryptoDepositElement v-bind:address="{ name: 'bitcoin', symbol: 'btc', address: cryptoDepositData.addresses.btc, info: 'Only transfer BTC to this wallet using the BTC network. You will receive your funds after 1 confirmation(s). Minimum required deposit amount is 0.0001 BTC after fees. Depositing less than the minimum requirement amount will result in a permanent loss.' }" />
                <CryptoDepositElement v-bind:address="{ name: 'ethereum', symbol: 'eth', address: cryptoDepositData.addresses.eth, info: 'Only transfer ETH to this wallet using the ERC20 network. You will receive your funds after 13 confirmation(s). Minimum required deposit amount is 0.005 ETH after fees. Depositing less than the minimum requirement amount will result in a permanent loss.' }" />
                <CryptoDepositElement v-bind:address="{ name: 'litecoin', symbol: 'ltc', address: cryptoDepositData.addresses.ltc, info: 'Only transfer LTC to this wallet using the LTC network. You will receive your funds after 6 confirmation(s). Minimum required deposit amount is 0.01 LTC after fees. Depositing less than the minimum requirement amount will result in a permanent loss.' }" />
                <CryptoDepositElement v-bind:address="{ name: 'doge ', symbol: 'doge', address: cryptoDepositData.addresses.doge, info: 'Only transfer DOGE to this wallet using the DOGE network. You will receive your funds after 6 confirmation(s). Minimum required deposit amount is 30 DOGE after fees. Depositing less than the minimum requirement amount will result in a permanent loss.' }" />
                <CryptoDepositElement v-bind:address="{ name: 'tron', symbol: 'trx', address: cryptoDepositData.addresses.trx, info: 'Only transfer TRX to this wallet using the TRX network. You will receive your funds after 20  confirmation(s). Minimum required deposit amount is 20 TRX after fees. Depositing less than the minimum requirement amount will result in a permanent loss.' }" />
                <CryptoDepositElement v-bind:address="{ name: 'tether ', symbol: 'usdt', address: cryptoDepositData.addresses.usdt, info: 'Only transfer USDT to this wallet using the ERC20 network. You will receive your funds after 13 confirmation(s). Minimum required deposit amount is 3.5 USDT after fees. Depositing less than the minimum requirement amount will result in a permanent loss.' }" />

            </div>
        </transition>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import CryptoDepositElement from '@/components/crypto/CryptoDepositElement';

    export default {
        name: 'DepositCrypto',
        components: {
            CryptoDepositElement
        },
        methods: {
            ...mapActions([
                'cryptoGetDepositDataSocket'
            ])
        },
        computed: {
            ...mapGetters([
                'generalSettings', 
                'cryptoDepositData'
            ])
        },
        created() {
            if(this.generalSettings.crypto.deposit.enabled === false) {
                this.$router.push({ name: 'DepositRust' });
            }

            if(this.cryptoDepositData.addresses === null && this.cryptoDepositData.loading === false) {
                this.cryptoGetDepositDataSocket();
            }
        }
    }
</script>

<style scoped>
    .deposit-crypto {
        width: 100%;
        margin-top: 35px;
    }

    .deposit-crypto .crypto-title {
        display: flex;
        align-items: center;
    }

    .deposit-crypto .title-value {
        font-size: 20px;
        font-weight: 800;
        color: #323743;
    }

    .deposit-crypto .title-value span {
        font-size: 26px;
    }

    .deposit-crypto .title-bonus {
        height: 26px;
        display: flex;
        align-items: center;
        margin-left: 12px;
        padding: 0 8px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
    }

    .deposit-crypto .crypto-loading {
        width: 100%;
    }

    .deposit-crypto .crypto-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .deposit-crypto .crypto-loading.fade-leave-to {
        opacity: 0;
    }

    .deposit-crypto .loading-element {
        width: 100%;
        height: 66px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #212732;
    }

    .deposit-crypto .element-image {
        width: 34px;
        height: 34px;
        position: relative;
        margin-right: 12px;
        border-radius: 50%;
        background: #212732;
        overflow: hidden;
    }

    .deposit-crypto .element-name {
        width: 80px;
        height: 20px;
        position: relative;
        border-radius: 3px;
        background: #212732;
        overflow: hidden;
    }

    .deposit-crypto .element-image::after,
    .deposit-crypto .element-name::after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        animation-name: loading_animation;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        background: linear-gradient(to right, #ffffff00 0%, rgba(255, 255, 255, .1) 50%, #ffffff00 100%);
    }

    @keyframes loading_animation {
        0% { transform: translateX(-100%); }
        50% { transform: translateX(100%); }
        100% { transform: translateX(100%); }
    }

    .deposit-crypto .crypto-content {
        width: 100%;
    }

    .deposit-crypto .crypto-content.fade-enter-active {
        transition: opacity 0.5s;
    }

    .deposit-crypto .crypto-content.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 425px) {

        .deposit-crypto {
            height: calc(100% - 180px);
        }

    }
</style>
