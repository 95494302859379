import { render, staticRenderFns } from "./CreditTransactionElement.vue?vue&type=template&id=600f1fe9&scoped=true"
import script from "./CreditTransactionElement.vue?vue&type=script&lang=js"
export * from "./CreditTransactionElement.vue?vue&type=script&lang=js"
import style0 from "./CreditTransactionElement.vue?vue&type=style&index=0&id=600f1fe9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "600f1fe9",
  null
  
)

export default component.exports