<template>
    <div class="steam-selected-element">
        <div class="element-remove">
            <button v-on:click="steamRemoveButton()">
                <svg width="12" height="12" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.01041 1.71762C0.619883 2.10814 0.619884 2.74131 1.01041 3.13183L5.6066 7.72803L1.01041 12.3242C0.619883 12.7147 0.619883 13.3479 1.01041 13.7384L1.71751 14.4455C2.10804 14.8361 2.7412 14.8361 3.13173 14.4455L7.72792 9.84935L12.3241 14.4455C12.7146 14.8361 13.3478 14.8361 13.7383 14.4455L14.4454 13.7384C14.836 13.3479 14.836 12.7147 14.4454 12.3242L9.84924 7.72803L14.4454 3.13183C14.836 2.74131 14.836 2.10814 14.4454 1.71762L13.7383 1.01051C13.3478 0.619988 12.7146 0.619988 12.3241 1.01051L7.72792 5.60671L3.13173 1.01051C2.7412 0.619989 2.10804 0.619988 1.71751 1.01051L1.01041 1.71762Z" />
                </svg>
            </button>
        </div>
        <div class="element-image">
            <img v-bind:src="item.image" alt="item-image" />
        </div>
        <div class="element-info">
            <div class="info-name">{{item.name}}</div>
            <div class="info-price">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="price-amount">
                    <span>{{generalFormatAmount(item.amount).split('.')[0]}}</span>.{{generalFormatAmount(item.amount).split('.')[1]}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapActions } from 'vuex';

    export default {
        name: 'SteamSelectedElement',
        mixins: [
            mixins
        ],
        props: [
            'item'
        ],
        methods: {
            ...mapActions([
                'steamRemoveDepositSelected', 
                'steamRemoveWithdrawSelected'
            ]),
            steamRemoveButton() {
                if(this.$route.name === 'DepositRust') {
                    this.steamRemoveDepositSelected(this.item);
                } else {
                    this.steamRemoveWithdrawSelected(this.item);
                }
            }
        }
    }
</script>

<style scoped>
    .steam-selected-element {
        width: 100%;
        height: 88px;
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding: 0 20px;
        border-radius: 12px;
        background: #1e212e;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: all 0.3s ease;
    }

    .steam-selected-element:first-of-type {
        margin-top: 0;
    }

    .steam-selected-element:hover {
        background: #222633;
    }

    .steam-selected-element .element-remove {
        position: absolute;
        top: 8px;
        right: 8px;
        transition: all 0.3s ease;
        opacity: 0;
    }

    .steam-selected-element:hover .element-remove {
        opacity: 1;
    }

    .steam-selected-element .element-remove button {
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: #1a1e2a;
    }

    .steam-selected-element .element-remove button svg {
        width: 13px;
        height: 13px;
        fill: #626C7e;
    }

    .steam-selected-element .element-image {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background: #1a1e2a;
    }

    .steam-selected-element .element-image img {
        width: 72px;
        height: 72px;
    }

    .steam-selected-element .element-info {
        padding-left: 28px;
    }

    .steam-selected-element .info-name {
        font-size: 12px;
        font-weight: 700;
        color: #596076;
    }

    .steam-selected-element .info-price {
        display: flex;
        align-items: center;
        margin-top: 11px;
    }

    .steam-selected-element .info-price img {
        width: 21px;
        margin-right: 10px;
    }

    .steam-selected-element .price-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .steam-selected-element .price-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }
</style>
