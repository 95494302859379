<template>
    <div class="profile">
        <div class="profile-nav">
            <router-link to="/profile" class="nav-link">OVERVIEW</router-link>
            <router-link to="/profile/settings" class="nav-link">SETTINGS</router-link>
            <router-link to="/profile/bets" class="nav-link">BETS</router-link>
            <router-link to="/profile/transactions" class="nav-link">TRANSACTIONS</router-link>
        </div>
        <div class="profile-content">
            <transition name="slide-fade" mode="out-in">
                <router-view/>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Support',
        metaInfo: {
            title: 'Profile - RustyRocket.GG'
        }
    }
</script>

<style scoped>
    .profile {
        width: 100%;
        min-height: calc(100vh - 112px);
        display: flex;
        padding: 40px;
    }

    .profile .profile-nav {
        width: 180px;
        display: flex;
        flex-direction: column;
    }

    .profile .profile-nav a.nav-link {
        width: 100%;
        height: 41px;
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
        transition: all 0.3s ease;
    }

    .profile .profile-nav a.nav-link:first-of-type {
        margin-top: 0;
    }

    .profile .profile-nav a.nav-link:hover {
        color: #ffffff;
    }

    .profile .profile-nav a.nav-link.router-link-exact-active {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .profile .profile-content {
        width: calc(100% - 180px);
        padding-left: 20px;
    }

    .profile .profile-content .slide-fade-enter-active {
        transition: all .3s ease-out;
    }

    .profile .profile-content .slide-fade-enter {
        opacity: 0;
    }

    @media only screen and (max-width: 1650px) {

        .profile {
            padding: 40px 15px;
        }

    }

    @media only screen and (max-width: 850px) {

        .profile {
            flex-direction: column;
        }

        .profile .profile-nav {
            width: 100%;
        }

        .profile .profile-content {
            width: 100%;
            margin-top: 20px;
            padding-left: 0;
        }

    }
</style>
