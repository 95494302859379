<template>
    <div class="gift-transaction-element">
        <div class="element-amount">
            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
            <div class="amount-value">
                <span>+{{generalFormatAmount(transaction.amount).split('.')[0]}}</span>.{{generalFormatAmount(transaction.amount).split('.')[1]}}
            </div>
        </div>
        <div class="element-state" v-bind:class="'state-'+transaction.state">{{transaction.state.toUpperCase()}}</div>
    </div>
</template>

<script>
    export default {
        name: 'GiftTransactionElement',
        props: [
            'transaction'
        ]
    }
</script>

<style scoped>
    .gift-transaction-element {
        width: 100%;
        height: 41px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 11px;
        padding: 0 12px;
        border-radius: 5px;
        background: #212732;
    }

    .gift-transaction-element:first-of-type {
        margin-top: 0;
    }

    .gift-transaction-element .element-amount {
        display: flex;
        align-items: center;
    }

    .gift-transaction-element .element-amount img {
        width: 21px;
        margin-right: 10px;
    }

    .gift-transaction-element .amount-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .gift-transaction-element .amount-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .gift-transaction-element .element-state {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
    }

    .gift-transaction-element .element-state.state-completed {
        color: #00c74d;
    }
</style>
