<template>
    <div class="crypto-deposit-element" v-bind:class="{ 'element-open': cryptoAccordion === true }">
        <button v-on:click="cryptoToggleAccordion()" class="element-toggle">
            <div class="button-info">
                <img v-bind:src="require('@/assets/img/cashier/'+address.symbol+'.webp')" alt="icon" />
                {{address.name}}
            </div>
            <IconCaretDown />
        </button>
        <transition name="slide">
            <div class="element-accordion" v-if="cryptoAccordion === true">
                <div class="accordion-inner">
                    <div class="inner-barcode">
                        <QRCode v-bind:value="address.address" v-bind:options="{ width: 114, height: 114, margin: 0 }" />
                    </div>
                    <div class="inner-info">
                        <div class="info-title">Your {{address.symbol.toUpperCase()}} deposit address</div>
                        <div class="info-address">
                            <div class="address-value">{{address.address}}</div>
                            <button v-on:click="cryptoCopyButton(address.address)" class="button-copy">COPY</button>
                        </div>
                        <div class="info-text">{{address.info}}</div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapActions } from 'vuex';
    import IconCaretDown from '@/components/icons/IconCaretDown';
    import QRCode from '@/components/QRCode';

    export default {
        name: 'CryptoDepositElement',
        components: {
            IconCaretDown,
            QRCode
        },
        mixins: [
            mixins
        ],
        props: [
            'address'
        ],
        data() {
            return {
                cryptoAccordion: false
            }
        },
        methods: {
            ...mapActions([
                'notificationShow'
            ]),
            cryptoToggleAccordion() {
                this.cryptoAccordion = !this.cryptoAccordion;
            },
            cryptoCopyButton(value) {
                console.log(value);
                this.generalCopyValue(value);
                this.notificationShow({ type: 'success', message: 'Copied to your clipboard.' });
            }
        }
    }
</script>

<style scoped>
    .crypto-deposit-element {
        width: 100%;
    }

    .crypto-deposit-element button.element-toggle {
        width: 100%;
        height: 66px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #212732;
    }

    .crypto-deposit-element button.element-toggle svg {
        height: 9px;
        fill: #626c7e;
        transition: all 0.3s ease;
    }

    .crypto-deposit-element.element-open button.element-toggle svg {
        fill: #ffffff;
        transform: rotate(180deg);
    }

    .crypto-deposit-element button.element-toggle .button-info {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        color: #626c7e;
        transition: all 0.3s ease;
    }

    .crypto-deposit-element.element-open button.element-toggle .button-info {
        color: #ffffff;
    }

    .crypto-deposit-element button.element-toggle .button-info img {
        width: 34px;
        height: 34px;
        margin-right: 12px;
        transition: all 0.3s ease;
        opacity: 0.5;
    }

    .crypto-deposit-element.element-open button.element-toggle .button-info img {
        opacity: 1;
    }

    .crypto-deposit-element .element-accordion {
        width: 100%;
    }

    .crypto-deposit-element .element-accordion.slide-enter-active,
    .crypto-deposit-element .element-accordion.slide-leave-active {
        overflow: hidden;
        transition: height 0.2s ease;
    }

    .crypto-deposit-element .element-accordion.slide-enter-to,
    .crypto-deposit-element .element-accordion.slide-leave {
        height: 168px;
    }

    .crypto-deposit-element .element-accordion.slide-enter,
    .crypto-deposit-element .element-accordion.slide-leave-to {
        height: 0;
    }

    .crypto-deposit-element .accordion-inner {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 18px 0;
    }

    .crypto-deposit-element .inner-barcode {
        width: 132px;
        height: 132px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: #ffffff;
    }

    .crypto-deposit-element .inner-info {
        width: calc(100% - 132px);
        padding-left: 25px;
    }

    .crypto-deposit-element .info-title {
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
    }

    .crypto-deposit-element .info-address {
        margin-top: 6px;
        display: flex;
        align-items: center;
    }

    .crypto-deposit-element button.button-copy {
        height: 32px;
        margin-left: 12px;
        padding: 0 10px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    .crypto-deposit-element button.button-copy:hover {
        background: #fe524a;
    }

    .crypto-deposit-element .address-value {
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        text-decoration: underline;
        font-size: 18px;
        font-weight: 600;
        color: #fd3b31;
    }

    .crypto-deposit-element .info-text {
        margin-top: 16px;
        font-size: 12px;
        font-weight: 700;
        color: #464b57;
    }

    @media only screen and (max-width: 975px) {

        .crypto-deposit-element .element-accordion.slide-enter-to,
        .crypto-deposit-element .element-accordion.slide-leave {
            height: 304px;
        }

        .crypto-deposit-element .accordion-inner {
            flex-direction: column;
        }

        .crypto-deposit-element .inner-info {
            width: 100%;
            margin-top: 25px;
            padding-left: 0;
        }

    }
    @media only screen and (max-width: 600px) {

        .crypto-deposit-element .element-accordion.slide-enter-to,
        .crypto-deposit-element .element-accordion.slide-leave {
            height: 361px;
        }

        .crypto-deposit-element .info-address {
            flex-direction: column;
            align-items: flex-start;
        }

        .crypto-deposit-element .info-address button.button-copy {
            width: 100%;
            height: 32px;
            margin-top: 12px;
            margin-left: 0;
        }

    }
</style>
