var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deposit-gift"},[_vm._m(0),_c('div',{staticClass:"gift-redeem"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.depositCode),expression:"depositCode"}],attrs:{"type":"text","placeholder":"XXXX - XXXX - XXXX - XXXX"},domProps:{"value":(_vm.depositCode)},on:{"input":function($event){if($event.target.composing)return;_vm.depositCode=$event.target.value}}}),_c('button',{attrs:{"disabled":_vm.socketSendLoading !== null},on:{"click":function($event){return _vm.depositRedeemButton()}}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.socketSendLoading === 'GiftClaim')?_c('div',{key:"loading",staticClass:"button-loading"},[_c('LoadingAnimation')],1):_c('div',{key:"content",staticClass:"button-content"},[_vm._v(" REDEEM GIFTCARD ")])])],1)]),_c('div',{staticClass:"gift-select"},[_c('div',{staticClass:"select-actions"},[_vm._m(1),_c('a',{attrs:{"href":_vm.depositGetLink,"target":"_blank"}},[_vm._v("BUY SELECTED GIFTCARD")])]),_c('div',{staticClass:"select-options"},[_c('button',{class:['options-element', { 
                'element-active': _vm.depositGift === '5' 
            }],on:{"click":function($event){return _vm.depositSetGift('5')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(2)]),_c('button',{class:['options-element', { 
                'element-active': _vm.depositGift === '10' 
            }],on:{"click":function($event){return _vm.depositSetGift('10')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(3)]),_c('button',{class:['options-element', { 
                'element-active': _vm.depositGift === '25' 
            }],on:{"click":function($event){return _vm.depositSetGift('25')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(4)]),_c('button',{class:['options-element', { 
                'element-active': _vm.depositGift === '50' 
            }],on:{"click":function($event){return _vm.depositSetGift('50')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(5)]),_c('button',{class:['options-element', { 
                'element-active': _vm.depositGift === '100' 
            }],on:{"click":function($event){return _vm.depositSetGift('100')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(6)]),_c('button',{class:['options-element', { 
                'element-active': _vm.depositGift === '250' 
            }],on:{"click":function($event){return _vm.depositSetGift('250')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(7)]),_c('button',{class:['options-element', { 
                'element-active': _vm.depositGift === '500' 
            }],on:{"click":function($event){return _vm.depositSetGift('500')}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/coins.webp"),"alt":"coins-image"}}),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gift-title"},[_c('div',{staticClass:"title-value"},[_c('span',[_vm._v("GIFT")]),_vm._v("CARD")]),_c('div',{staticClass:"title-bonus"},[_vm._v("+35% BONUS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"actions-method"},[_c('div',{staticClass:"method-element"},[_c('img',{attrs:{"src":require("@/assets/img/icons/kinguin.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-amount"},[_c('span',[_vm._v("5")]),_vm._v(".00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-amount"},[_c('span',[_vm._v("10")]),_vm._v(".00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-amount"},[_c('span',[_vm._v("25")]),_vm._v(".00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-amount"},[_c('span',[_vm._v("50")]),_vm._v(".00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-amount"},[_c('span',[_vm._v("100")]),_vm._v(".00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-amount"},[_c('span',[_vm._v("250")]),_vm._v(".00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"element-amount"},[_c('span',[_vm._v("500")]),_vm._v(".00 ")])
}]

export { render, staticRenderFns }