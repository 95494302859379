<template>
    <div class="steam-sidebar-selected">
        <div class="selected-header">
            <button v-on:click="steamEmptyButton()" class="button-clear">CLEAR SELECTION</button>
            <button v-on:click="steamCloseButton()" class="button-close">
                <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.01041 1.71762C0.619883 2.10814 0.619884 2.74131 1.01041 3.13183L5.6066 7.72803L1.01041 12.3242C0.619883 12.7147 0.619883 13.3479 1.01041 13.7384L1.71751 14.4455C2.10804 14.8361 2.7412 14.8361 3.13173 14.4455L7.72792 9.84935L12.3241 14.4455C12.7146 14.8361 13.3478 14.8361 13.7383 14.4455L14.4454 13.7384C14.836 13.3479 14.836 12.7147 14.4454 12.3242L9.84924 7.72803L14.4454 3.13183C14.836 2.74131 14.836 2.10814 14.4454 1.71762L13.7383 1.01051C13.3478 0.619988 12.7146 0.619988 12.3241 1.01051L7.72792 5.60671L3.13173 1.01051C2.7412 0.619989 2.10804 0.619988 1.71751 1.01051L1.01041 1.71762Z" />
                </svg>
            </button>
        </div>
        <div class="selected-content">

            <SteamSelectedElement v-for="(item, index) in steamGetSelected" v-bind:key="index" v-bind:item="item" />

        </div>
        <div class="selected-footer">
            <button v-on:click="steamSendButton()" class="button-send" v-bind:disabled="socketSendLoading !== null">
                <transition name="fade" mode="out-in">
                    <div v-if="socketSendLoading === 'SteamDeposit' || socketSendLoading === 'SteamWithdraw'" class="button-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else class="button-content" key="content">
                        {{ $route.name === 'DepositRust' ? 'DEPOSIT': 'WITHDRAW' }} {{steamGetSelected.length}} SKINS WORTH
                        <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                        <div class="button-amount">
                            <span>{{generalFormatAmount(steamGetSelectedAmount).split('.')[0]}}</span>.{{generalFormatAmount(steamGetSelectedAmount).split('.')[1]}}
                        </div>
                    </div>
                </transition>
            </button>
            <router-link to="/profile/settings" class="link-tradelink">SET STEAM TRADE URL</router-link>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import SteamSelectedElement from '@/components/steam/SteamSelectedElement';

    export default {
        name: 'SteamSidebarTransactions',
        components: {
            LoadingAnimation,
            SteamSelectedElement
        },
        mixins: [
            mixins
        ],
        methods: {
            ...mapActions([
                'notificationShow',
                'steamEmptyDepositSelected', 
                'steamEmptyWithdrawSelected',  
                'steamSendDepositSocket', 
                'steamSendWithdrawSocket'
            ]),
            steamEmptyButton() {
                this.steamEmptyDepositSelected();
                this.steamEmptyWithdrawSelected();
            },
            steamCloseButton() {
                if(this.$route.name === 'DepositRust') { this.$parent.depositSetSidebar(false); }
                else { this.$parent.withdrawSetSidebar(false); }
            },
            steamSendButton() {
                if(this.steamGetSelected.length <= 0) {
                    this.notificationShow({ type: 'error', message: 'You need to select at least 1 item.' });
                    return;
                }

                if(this.$route.name === 'DepositRust') {
                    if(this.generalSettings.steam.deposit.enabled === false && this.authUser.user.rank !== 'admin') {
                        this.notificationShow({ type: 'error', message: 'Your requested action is currently not available' });
                        return;
                    }

                    let items = [];

                    for(const selectedItem of this.steamDepositData.selected) {
                        if(items.some((element) => element.name === selectedItem.name) === false) {
                            const countItem = this.steamDepositData.selected.filter((element) => element.name === selectedItem.name).length;
                            let itemsUsed = 0;

                            for(const positionItem of selectedItem.positions) {
                                if(positionItem.count >= (countItem - itemsUsed)) {
                                    items.push({ assetid: positionItem.assetid, name: selectedItem.name, count: (countItem - itemsUsed) });
                                    break;
                                } else {
                                    items.push({ assetid: positionItem.assetid, name: selectedItem.name, count: positionItem.count });
                                    itemsUsed = itemsUsed + positionItem.count;
                                }
                            }
                        }
                    }

                    this.steamSendDepositSocket({ items: items });
                } else {
                    if(this.generalSettings.steam.withdraw.enabled === false && this.authUser.user.rank !== 'admin') {
                        this.notificationShow({ type: 'error', message: 'Your requested action is currently not available' });
                        return;
                    }

                    this.steamSendWithdrawSocket({ item: { _id: this.steamWithdrawData.selected[0].positions[0]._id } });
                }
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'generalSettings', 
                'authUser', 
                'steamDepositData',
                'steamWithdrawData'
            ]),
            steamGetSelected() {
                let items = this.$route.name === 'DepositRust' ? this.steamDepositData.selected : this.steamWithdrawData.selected;

                items.sort(function(a, b) { return +b.amount - +a.amount; });

                return items;
            },
            steamGetSelectedAmount() {
                let amount = 0;

                for(let item of this.steamGetSelected) {
                    amount = amount + item.amount;
                }

                return amount;
            }
        }
    }
</script>

<style scoped>
    .steam-sidebar-selected {
        width: 100%;
        height: 100%;
        padding: 18px;
    }

    .steam-sidebar-selected .selected-header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .steam-sidebar-selected button.button-clear {
        margin: 0;
        padding: 0;
        text-decoration: underline;
        font-size: 13px;
        font-weight: 600;
        color: #626c7e;
    }

    .steam-sidebar-selected button.button-clear:hover {
        color: #ffffff;
    }

    .steam-sidebar-selected button.button-close {
        width: 15px;
        height: 15px;
        display: none;
        margin: 0;
        padding: 0;
    }

    .steam-sidebar-selected button.button-close svg {
        fill: #626c7e;
        transition: all 0.3s ease;
    }

    .steam-sidebar-selected button.button-close:hover svg {
        fill: #ffffff;
    }

    .steam-sidebar-selected .selected-content {
        width: 100%;
        height: calc(100% - 143px);
        margin-top: 16px;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .steam-sidebar-selected .selected-content::-webkit-scrollbar-track {
        background: transparent;
    }

    .steam-sidebar-selected .selected-content::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .steam-sidebar-selected .selected-footer {
        width: 100%;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .steam-sidebar-selected .selected-footer button.button-send {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
        transition: all 0.3s ease;
    }

    .steam-sidebar-selected .selected-footer button.button-send:hover {
        background: #00de56;
    }

    .steam-sidebar-selected .selected-footer button.button-send.button-loading {
        background: #00c74d;
        cursor: not-allowed;
    }

    .steam-sidebar-selected .selected-footer button.button-send .button-loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .steam-sidebar-selected .selected-footer button.button-send .button-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .steam-sidebar-selected .selected-footer button.button-send .button-loading.fade-leave-to {
        opacity: 0;
    }

    .steam-sidebar-selected .selected-footer button.button-send .button-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .steam-sidebar-selected .selected-footer button.button-send .button-content.fade-enter-active {
        transition: opacity 0.5s;
    }

    .steam-sidebar-selected .selected-footer button.button-send .button-content.fade-enter-from {
        opacity: 0;
    }

    .steam-sidebar-selected .selected-footer button.button-send .button-content img {
        width: 21px;
        margin-left: 12px;
    }

    .steam-sidebar-selected .selected-footer button.button-send .button-content .button-amount {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .steam-sidebar-selected .selected-footer button.button-send .button-content span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .steam-sidebar-selected .selected-footer a.link-tradelink {
        margin-top: 16px;
        padding: 0;
        text-decoration: underline;
        font-size: 13px;
        font-weight: 600;
        color: #ffffff;
    }

    @media only screen and (max-width: 1300px) {

        .steam-sidebar-selected .selected-header {
            justify-content: space-between;
        }

        .steam-sidebar-selected button.button-close {
            display: flex;
            align-items: center;
        }

    }
</style>
