<template>
    <div class="deposit-rust">
        <div class="rust-header">
            <FilterSearch v-on:setFilterValue="depositSetFilterSearch" v-bind:filterValue="depositFilterSearch" />
            <div class="header-filter">
                <FilterItemPrice v-on:setFilterValue="depositSetFilterPrice" v-bind:filterValue="depositFilterPrice" />
                <FilterItemSort v-on:setFilterValue="depositSetFilterSort" v-bind:filterValue="depositFilterSort" />
            </div>
        </div>
        <div class="rust-content">
            <transition name="fade" mode="out-in">
                <div v-if="steamDepositData.loading === true" class="content-loading" key="loading">
                    <LoadingAnimation />
                </div>
                <div v-else-if="steamDepositData.items !== null && depositGetItems.length > 0" class="content-items" key="data">

                    <SteamItemElement v-for="item of depositGetItems.slice((depositPage - 1) * 60, depositPage * 60)" v-bind:key="item.assetid" v-bind:item="item" />

                </div>
                <div v-else class="content-empty" key="empty">NO ITEMS FOUND</div>
            </transition>
        </div>

        <Pagination v-on:setPage="depositSetPage" v-bind:page="depositPage" v-bind:count="depositGetItems.length" countPage="60" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import FilterSearch from '@/components/filters/FilterSearch';
    import FilterItemPrice from '@/components/filters/FilterItemPrice';
    import FilterItemSort from '@/components/filters/FilterItemSort';
    import SteamItemElement from '@/components/steam/SteamItemElement';

    export default {
        name: 'DepositRust',
        components: {
            LoadingAnimation,
            Pagination,
            FilterSearch,
            FilterItemPrice,
            FilterItemSort,
            SteamItemElement
        },
        data() {
            return {
                depositPage: 1,
                depositFilterSearch: '',
                depositFilterPrice: 'all',
                depositFilterSort: 'descending'
            }
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'steamEmptyDepositSelected', 
                'steamGetDepositDataSocket'
            ]),
            depositSetPage(value) {
                this.depositPage = value;
            },
            depositSetFilterSearch(value) {
                this.depositFilterSearch = value;
                this.depositSetPage(1);
            },
            depositSetFilterPrice(value) {
                this.depositFilterPrice = value;
                this.depositSetPage(1);
            },
            depositSetFilterSort(value) {
                this.depositFilterSort = value;
                this.depositSetPage(1);
            },
            depositReloadButton() {
                if((new Date().getTime() - this.steamDepositData.loadedAt) < 30 * 1000) {
                    this.notificationShow({ type: 'error', message: 'You need to wait 30 seconds before you can reload.' });
                    return;
                }

                this.steamEmptyDepositSelected();
                this.steamGetDepositDataSocket({});
            }
        },
        computed: {
            ...mapGetters([
                'steamDepositData'
            ]),
            depositGetItems() {
                let items = [];
                
                if(this.steamDepositData.items !== null) {
                    for(const item of this.steamDepositData.items) {
                        if(item.name.toLowerCase().includes(this.depositFilterSearch.toLowerCase().trim()) === true) {
                            const index = items.findIndex((element) => element.name === item.name);

                            if(index !== -1) {
                                items[index].count = items[index].count + item.count;
                                items[index].positions.push({ assetid: item.assetid, count: item.count });
                            } else {
                                items.push({
                                    name: item.name,
                                    image: item.image,
                                    amount: item.amount,
                                    count: item.count,
                                    accepted: item.accepted,
                                    positions: [
                                        {
                                            assetid: item.assetid,
                                            count: item.count
                                        }
                                    ]
                                });
                            }
                        }
                    }
                }

                if(this.steamDepositData.transactions !== null) {
                    for(const transaction of this.steamDepositData.transactions) {
                        for(const transactionItem of transaction.data.items) {
                            const indexItem = items.findIndex((element) => element.name === transactionItem.name);

                            if(indexItem !== -1) {
                                const indexPos = items[indexItem].positions.findIndex((element) => element.assetid === transactionItem.assetid);

                                if(indexPos !== -1) {
                                    items[indexItem].count = items[indexItem].count - transactionItem.count;
                                    items[indexItem].positions[indexPos].count = items[indexItem].positions[indexPos].count - transactionItem.count;

                                    if(items[indexItem].amount <= 0) { items.splice(indexItem, 1); }
                                }
                            }
                        }
                    }
                }

                if(this.depositFilterSort === 'descending') {
                    items.sort((a, b) => { return b.amount - a.amount; });
                } else {
                    items.sort((a, b) => { return a.amount - b.amount; });
                }
                items.sort((a, b) => { return b.accepted - a.accepted; });

                if(this.depositFilterPrice == '0.00 - 5.00') {
                    items = items.filter((element) => element.amount <= 5000);
                } else if(this.depositFilterPrice == '5.00 - 25.00') {
                    items = items.filter((element) => (element.amount > 5000 && element.amount <= 25000));
                } else if(this.depositFilterPrice == '25.00 - 100.00') {
                    items = items.filter((element) => (element.amount > 25000 && element.amount <= 100000));
                } else if(this.depositFilterPrice == '100.00+') {
                    items = items.filter((element) => element.amount > 100000);
                }

                return items;
            }
        },
        created() {
            this.steamEmptyDepositSelected();

            if(
                (this.steamDepositData.items === null || (new Date().getTime() - this.steamDepositData.loadedAt) >= 30 * 1000) && 
                this.steamDepositData.loading === false
            ) {
                this.steamGetDepositDataSocket({});
            }
        }
    }
</script>

<style scoped>
    .deposit-rust {
        width: 100%;
        margin-top: 35px;
    }

    .deposit-rust .rust-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .deposit-rust .header-search {
        display: flex;
        align-items: center;
    }

    .deposit-rust button.button-reload {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;
        border-radius: 8px;
        background: #212732;
        border-bottom: 2px solid #191e27;
    }

    .deposit-rust button.button-reload svg {
        width: 17px;
        fill: #767c8b;
        transition: all 0.3s ease;
    }

    .deposit-rust button.button-reload:disabled svg {
        animation: rotate_animation 1s ease infinite;
    }

    .deposit-rust button.button-reload:not([disabled]):hover svg {
        fill: #ffffff;
    }

    .deposit-rust .header-filter {
        display: flex;
        align-items: center;
    }

    .deposit-rust .rust-content {
        width: 100%;
        margin-top: 26px;
    }

    .deposit-rust .content-loading {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .deposit-rust .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .deposit-rust .content-loading.fade-leave-to {
        opacity: 0;
    }

    .deposit-rust .content-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .deposit-rust .content-empty {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #626c7e;
    }

    .deposit-rust .content-items.fade-enter-active,
    .deposit-rust .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .deposit-rust .content-items.fade-enter-from,
    .deposit-rust .content-empty.fade-enter-from {
        opacity: 0;
    }

    @keyframes rotate_animation {
        0% { transform: rotate(0deg); }
        50% { transform: rotate(180deg); }
        100% { transform: rotate(360deg); }
    }

    @media only screen and (max-width: 1350px) {

        .deposit-rust .rust-header {
            flex-direction: column;
            align-items: flex-start;
        }

        .deposit-rust .header-search {
            width: 100%;
        }

        .deposit-rust .header-search input {
            width: 100%;
        }

        .deposit-rust .header-filter {
            margin-top: 26px;
        }

    }

    @media only screen and (max-width: 475px) {

        .deposit-rust .header-filter {
            flex-direction: column;
            align-items: flex-start;
        }

    }
</style>
