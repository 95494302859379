<template>
    <div class="deposit-credit">
        <div class="credit-title">
            <div class="title-value"><span>CREDIT</span>CARD</div>
            <div class="title-bonus">+35% BONUS</div>
        </div>
        <div class="credit-deposit">
            <div class="deposit-amount">
                <IconFiat />
                <input v-model="creditAmount" v-on:input="creditAmount = generalFormatInputAmount(creditAmount)" type="text" />
            </div>
            <CreditFilterCurrency />
            <button v-on:click="creditDepositButton()" class="button-deposit" v-bind:disabled="socketSendLoading !== null">
                DEPOSIT ${{parseFloat(isNaN(creditAmount) === false ? Number(creditAmount) : 0).toFixed(2)}}
            </button>
        </div>
        <div class="credit-info">
            YOU WILL RECEIVE
            <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
            <div class="info-amount">
                <span>{{generalFormatAmount(creditGetReceiveAmount).split('.')[0]}}</span>.{{generalFormatAmount(creditGetReceiveAmount).split('.')[1]}}
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';
    import IconFiat from '@/components/icons/IconFiat';
    import CreditFilterCurrency from '@/components/credit/CreditFilterCurrency';

    export default {
        name: 'DepositCredit',
        components: {
            IconFiat,
            CreditFilterCurrency
        },
        mixins: [
            mixins
        ],
        data() {
            return {
                creditAmount: '0.00'
            }
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'creditGetDepositDataSocket',
                'creditSendDepositSocket'
            ]),
            creditDepositButton() {
                if(isNaN(this.creditAmount) === true || Number(this.creditAmount) < 5.00) {
                    this.notificationShow({type: 'error', message: 'Your entered deposit amount is invalid. The minimum deposit amount is $5.00.'});
                    return;
                }

                this.creditSendDepositSocket({ amount: this.creditGetReceiveAmount });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'generalSettings',
                'creditDepositData'
            ]),
            creditGetReceiveAmount() {
                let amount = 0;

                if(isNaN(this.creditAmount) === false) { 
                    amount = Math.floor(Number(this.creditAmount).toFixed(2) * 1000) * 1.35; 
                }

                return amount;
            }
        },
        created() {
            if(this.generalSettings.gift.deposit.enabled === false) {
                this.$router.push({ name: 'DepositRust' });
            }

            if(this.creditDepositData.transactions === null && this.creditDepositData.loading === false) {
                this.creditGetDepositDataSocket({});
            }
        }
    }
</script>

<style scoped>
    .deposit-credit {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 35px;
    }

    .deposit-credit .credit-title {
        display: flex;
        align-items: center;
    }

    .deposit-credit .title-value {
        font-size: 20px;
        font-weight: 800;
        color: #323743;
    }

    .deposit-credit .title-value span {
        font-size: 26px;
    }

    .deposit-credit .title-bonus {
        height: 26px;
        display: flex;
        align-items: center;
        margin-left: 12px;
        padding: 0 8px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #fd3b31;
    }

    .deposit-credit .credit-deposit {
        display: flex;
        align-items: center;
        margin-top: 18px;
        padding: 8px 15px 8px 8px;
        border-radius: 8px;
        background: #11141f;
    }

    .deposit-credit .deposit-amount {
        width: 240px;
        position: relative;
    }

    .deposit-credit .deposit-amount svg {
        height: 18px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
        fill: #00c74d;
    }

    .deposit-credit .deposit-amount input {
        width: 100%;
        height: 54px;
        padding: 0 18px 0 47px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .deposit-credit .deposit-amount input::placeholder {
        font-size: 13px;
        font-weight: 700;
        color: #596076;
    }

    .deposit-credit button.button-deposit {
        height: 41px;
        margin-left: 42px;
        padding: 0 16px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .deposit-credit button.button-deposit:hover {
        background: #00de56;
    }

    .deposit-credit .credit-info {
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .deposit-credit .credit-info img {
        width: 20px;
        height: 20px;
        margin-left: 12px;
    }

    .deposit-credit .credit-info .info-amount {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .deposit-credit .info-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    @media only screen and (max-width: 650px) {

        .deposit-credit .credit-deposit {
            width: 100%;
            flex-wrap: wrap;
        }

        .deposit-credit .deposit-amount {
            width: calc(100% - 117px);
        }

        .deposit-credit button.button-deposit {
            width: 100%;
            margin-top: 12px;
            margin-left: 0;
        }

    }
</style>