<template>
    <div class="profile-overview">
        <div class="overview-user">
            <AvatarImage v-bind:image="authUser.user.avatar" />
            <div class="user-info">
                <div class="info-username">
                    <span v-html="authUser.user.username"></span>
                    <span class="user-rank" v-bind:class="{ 'rank-mod': authUser.user.rank === 'mod', 'rank-admin': authUser.user.rank === 'admin' }">{{authUser.user.rank.toUpperCase()}}</span>
                </div>
                <div class="info-level">
                    <div class="level-text">
                        To the next level
                        <div class="text-value">
                            <span>{{ profileGetUserLevelProgress }}</span>/{{ profileGetLevelXp(profileGetUserLevel + 1) - profileGetLevelXp(profileGetUserLevel) }} XP
                        </div>
                    </div>
                    <div class="level-info">
                        <div class="info-current">
                            <IconStar />
                            {{ profileGetUserLevel }}
                        </div>
                        <div class="info-progress">
                            <div class="progress-inner" v-bind:style="{ 'width': profileGetLevelPercentage + '%' }"></div>
                        </div>
                        <div class="info-next">
                            <IconStar />
                            {{ profileGetUserLevel >= 100 ? 100 : profileGetUserLevel + 1  }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overview-stats">
            <div class="stats-element">
                <div class="element-title">TOTAL WAGERED</div>
                <div class="element-value">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="value-amount">
                        <span>{{generalFormatAmount(authUser.user.stats.total.bet).split('.')[0]}}</span>.{{generalFormatAmount(authUser.user.stats.total.bet).split('.')[1]}}
                    </div>
                </div>
            </div>
            <div class="stats-element">
                <div class="element-title">TOTAL DEPOSITED</div>
                <div class="element-value">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="value-amount">
                        <span>{{generalFormatAmount(authUser.user.stats.total.deposit).split('.')[0]}}</span>.{{generalFormatAmount(authUser.user.stats.total.deposit).split('.')[1]}}
                    </div>
                </div>
            </div>
            <div class="stats-element">
                <div class="element-title">TOTAL WITHDRAWN</div>
                <div class="element-value">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="value-amount">
                        <span>{{generalFormatAmount(authUser.user.stats.total.withdraw).split('.')[0]}}</span>.{{generalFormatAmount(authUser.user.stats.total.withdraw).split('.')[1]}}
                    </div>
                </div>
            </div>
            <div class="stats-element element-profit" v-bind:class="{ 'element-negative': profileGetProfit < 0 }">
                <div class="element-title">TOTAL PROFIT</div>
                <div class="element-value">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="value-amount">
                        <span>{{generalFormatAmount(profileGetProfit).split('.')[0]}}</span>.{{generalFormatAmount(profileGetProfit).split('.')[1]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters } from 'vuex';
    import IconStar from '@/components/icons/IconStar';
    import AvatarImage from '@/components/AvatarImage';

    export default {
        name: 'ProfileOverview',
        components: {
            IconStar,
            AvatarImage
        },
        mixins: [
            mixins
        ],
        methods: {
            profileGetLevelXp(level) {
                return Math.pow(level * 4, 2) * 100;
            }
        },
        computed: {
            ...mapGetters([
                'authUser'
            ]),
            profileGetUserLevel() {
                let level = 0;

                if(this.authUser.user !== null) { level = Math.floor(Math.sqrt(this.authUser.user.xp / 1000) / 4) }

                return level >= 100 ? 100 : level;
            },
            profileGetUserLevelProgress() {
                let progress = 0;

                if(this.authUser.user !== null) { 
                    progress = this.profileGetUserLevel === 100 ? this.profileGetLevelXp(this.profileGetUserLevel) : Math.floor(this.authUser.user.xp / 10) - this.profileGetLevelXp(this.profileGetUserLevel); 
                }

                return progress;
            },
            profileGetLevelPercentage() {
                let percentage = 100 / (this.profileGetLevelXp(this.profileGetUserLevel + 1) - this.profileGetLevelXp(this.profileGetUserLevel)) * this.profileGetUserLevelProgress;

                if(percentage >= 100) { percentage = 100; }

                return percentage;
            },
            profileGetProfit() {
                return Math.floor(this.authUser.user.stats.total.withdraw -  this.authUser.user.stats.total.deposit);
            }
        }
    }
</script>

<style scoped>
    .profile-overview {
        width: 100%;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .profile-overview .overview-user {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 30px 30px 0 30px;
    }

    .profile-overview .overview-user .avatar-image {
        width: 100px;
        height: 100px;
        border-radius: 12px;
    }

    .profile-overview .user-info {
        width: calc(100% - 100px);
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 5px;
        padding-left: 15px;
    }

    .profile-overview .info-username {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .profile-overview .info-username span.user-rank {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .profile-overview .info-username span.user-rank.rank-mod {
        color: #00c74d;
    }

    .profile-overview .info-username span.user-rank.rank-admin {
        color: #fd3b31;
    }

    .profile-overview .info-level {
        width: 100%;
        padding: 8px 10px 10px 10px;
        border-radius: 8px;
        background: #12161d;
    }

    .profile-overview .level-text {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #767c8b;
    }

    .profile-overview .text-value span {
        color: #ffffff;
    }

    .profile-overview .level-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
    }

    .profile-overview .info-current,
    .profile-overview .info-next {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 700;
        color: #ffffff;
        background: #a1aeb5;
    }

    .profile-overview .info-next {
        background: #1fb1ff;
    }

    .profile-overview .info-current svg,
    .profile-overview .info-next svg {
        width: 11px;
        margin-right: 5px;
        margin-bottom: 1px;
        fill: #ffffff;
    }

    .profile-overview .info-progress {
        width: calc(100% - 98px);
        height: 8px;
        border-radius: 3px;
        background: #212732;
    }

    .profile-overview .progress-inner {
        height: 100%;
        border-radius: 3px;
        background: #fd3b31;
    }

    .profile-overview .overview-stats {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 12px;
        padding: 25px 30px 25px 30px;
        border-top: 1px solid #212732;
    }

    .profile-overview .stats-element {
        width: calc(33.33% - 16px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-overview .stats-element.element-profit {
        width: 100%;
        margin-top: 16px;
    }

    .profile-overview .element-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .profile-overview .stats-element.element-profit .element-title {
        color: #00c74d;
    }

    .profile-overview .stats-element.element-profit.element-negative .element-title {
        color: #fd3b31;
    }

    .profile-overview .element-value {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
        background: #212732;
    }

    .profile-overview .stats-element.element-profit .element-value {
        color: #00c74d;
        background: rgba(0, 199, 77, 0.08);
    }

    .profile-overview .stats-element.element-profit.element-negative .element-value {
        color: #fd3b31;
        background: rgba(253, 59, 49, 0.08);
    }

    .profile-overview .element-value img {
        width: 21px;
        margin-right: 10px;
    }

    .profile-overview .value-amount {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .profile-overview .stats-element.element-profit .value-amount {
        color: #00c74d;
    }

    .profile-overview .stats-element.element-profit.element-negative .value-amount {
        color: #fd3b31;
    }

    .profile-overview .value-amount span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .profile-overview .stats-element.element-profit .value-amount span {
        color: #00c74d;
    }

    .profile-overview .stats-element.element-profit.element-negative .value-amount span {
        color: #fd3b31;
    }

    @media only screen and (max-width: 900px) {

        .profile-overview .stats-element {
            width: calc(50% - 8px);
            margin-top: 16px;
        }

        .profile-overview .stats-element:first-of-type {
            width: 100%;
            margin-top: 0;
        }

    }

    @media only screen and (max-width: 550px) {

        .profile-overview .overview-user {
            width: 100%;
            flex-direction: column;
            padding: 30px 15px 0 15px;
        }

        .profile-overview .user-info {
            width: 100%;
            align-items: center;
            padding-left: 0;
        }

        .profile-overview .info-username {
            margin-top: 20px;
        }

        .profile-overview .overview-stats {
            padding: 25px 15px 25px 15px;
        }

    }
</style>
