<template>
    <div class="credit-filter-currency" v-bind:class="{ 'currency-open': creditDropdown === true }">
        <button class="button-toggle" v-on:click="creditSetDropdown(!creditDropdown)">
            <IconFiat />
            {{creditFilterCurrency.toUpperCase()}}
            <IconCaretDown />
        </button>
        <div class="currency-menu">
            <div class="menu-inner">
                <button v-on:click="creditSetValue('usd')">USD</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconFiat from '@/components/icons/IconFiat';
    import IconCaretDown from '@/components/icons/IconCaretDown';

    export default {
        name: 'CreditFilterCurrency',
        components: {
            IconFiat,
            IconCaretDown
        },
        data() {
            return {
                creditDropdown: false
            }
        },
        methods: {
            ...mapActions([
                'creditSetFilterCurrency'
            ]),
            creditSetDropdown(value) {
                this.creditDropdown = value;
            },
            creditSetValue(value) {
                this.creditSetFilterCurrency(value);
                this.creditSetDropdown(false);
            }
        },
        computed: {
            ...mapGetters([
                'creditFilterCurrency'
            ])
        },
        mounted() {
            let self = this;
            document.addEventListener('click', function(event) {
                if(!self.$el.contains(event.target) && self.creditDropdown === true) {
                    self.creditSetDropdown(false);
                }
            });
        }
    }
</script>

<style scoped>
    .credit-filter-currency {
        width: 105px;
        position: relative;
        margin-left: 12px;
    }

    .credit-filter-currency button.button-toggle {
        width: 100%;
        height: 54px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 18px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .credit-filter-currency button.button-toggle svg.icon-currency {
        width: 15px;
        height: 18px;
        position: absolute;
        top: 18px;
        left: 12px;
        fill: #00c74d;
    }

    .credit-filter-currency button.button-toggle svg.icon-toggle {
        margin-bottom: 1px;
        margin-left: 6px;
        fill: #ffffff;
        transition: transform 0.3s ease;
    }

    .credit-filter-currency.currency-open button.button-toggle svg.icon-toggle {
        transform: rotate(180deg);
    }

    .credit-filter-currency .currency-menu {
        width: 100%;
        height: 0;
        position: absolute;
        top: 58px;
        left: 50%;
        transform: translate(-50%, 0);
        overflow: hidden;
        transition: height 0.2s ease;
        z-index: 10;
    }

    .credit-filter-currency.currency-open .currency-menu {
        height: 43px;
    }

    .credit-filter-currency .menu-inner {
        width: 100%;
        padding: 3px;
        border-radius: 5px;
        background: #212732;
        border: 1px solid #191e27;
    }

    .credit-filter-currency .menu-inner button {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .credit-filter-currency .menu-inner button:hover {
        background: #191d26;
    }
</style>